
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({
  name: 'DiagramCameraControls'
})
export default class extends Vue {
  expanded = false

  @Prop() readonly scale!: number

  scaleMin = 0.06
  scaleMax = 1.2

  get step () {
    return (this.scaleMax - this.scaleMin) / 10
  }

  created () {
    try {
      this.expanded = localStorage.getItem('cameraControls') === 'true'
    } catch (err) {
      console.error(err)
    }
  }

  toggle () {
    this.expanded = !this.expanded

    try {
      localStorage.setItem('cameraControls', `${this.expanded}`)
    } catch (err) {
      console.error(err)
    }
  }
}
